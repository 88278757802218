import React, { useState } from "react";
import "./App.css";

const Verifier = () => {
  const [emails, setEmails] = useState([]);
  const [results, setResults] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [singleEmail, setSingleEmail] = useState("");
  const [mode, setMode] = useState("single");

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/plain") {
      const reader = new FileReader();
      reader.onload = () => {
        const emailList = reader.result.split("\n").map(email => email.trim()).filter(email => email);
        setEmails(emailList);
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a valid .txt file.");
    }
  };

  const handleSingleEmailChange = (e) => {
    setSingleEmail(e.target.value);
  };

  const retryRequest = async (chunk, retries = 3) => {
    let attempts = 0;
    while (attempts < retries) {
      try {
        const response = await fetch("https://27o8zovvgk.execute-api.us-east-1.amazonaws.com/yahoo_validator", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ type: "bulk", emails: chunk }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("API Error:", errorText);
          throw new Error(`API returned an error: ${response.statusText}`);
        }

        const data = await response.json();
        return data.results.map((result) => ({
          email: result.email, // Ensure full email is included
          status: result.status,
          reason: result.reason,
        }));
      } catch (error) {
        console.error(`Retry attempt ${attempts + 1} failed:`, error);
        attempts++;
        if (attempts >= retries) throw error;
      }
    }
  };

  const verifySingleEmail = async () => {
    setIsProcessing(true);
    try {
      const response = await fetch("https://27o8zovvgk.execute-api.us-east-1.amazonaws.com/yahoo_validator", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ type: "single", email: singleEmail }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("API Error:", errorText);
        throw new Error(`API returned an error: ${response.statusText}`);
      }

      const data = await response.json();
      setResults([
        {
          email: data.email, // Full email returned by the API
          status: data.status,
          reason: data.reason,
        },
      ]);
    } catch (error) {
      console.error("Error verifying email:", error);
    }
    setIsProcessing(false);
  };

  const verifyBulkEmails = async () => {
    setIsProcessing(true);
    setProgress(0);
    const chunkSize = 20;
    const allResults = [];

    for (let i = 0; i < emails.length; i += chunkSize) {
      const chunk = emails.slice(i, i + chunkSize);
      try {
        const results = await retryRequest(chunk);
        allResults.push(...results);
        setProgress(((i + chunkSize) / emails.length) * 100);
      } catch (error) {
        console.error("Error verifying emails:", error);
        allResults.push(
          ...chunk.map((email) => ({
            email, // Full email used here
            status: "Error",
            reason: "Failed after retries",
          }))
        );
      }
    }

    setResults(allResults);
    setIsProcessing(false);
  };

  const downloadResults = () => {
    const csvContent =
      "email,status,reason\n" +
      results
        .map((res) => `${res.email},${res.status},${res.reason}`)
        .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "results.csv";
    link.click();
  };

  return (
    <div className="verifier-container">
      <h1>Yahoo Email Verifier</h1>
      <div>
        <button onClick={() => setMode("single")} disabled={mode === "single"}>Verify Single Email</button>
        <button onClick={() => setMode("bulk")} disabled={mode === "bulk"}>Bulk Verify Emails</button>
      </div>
      {mode === "single" && (
        <div>
          <input
            type="text"
            placeholder="Enter email"
            value={singleEmail}
            onChange={handleSingleEmailChange}
          />
          <button onClick={verifySingleEmail} disabled={isProcessing || !singleEmail}>
            {isProcessing ? "Processing..." : "Verify Email"}
          </button>
        </div>
      )}
      {mode === "bulk" && (
        <div>
          <input type="file" accept=".txt" onChange={handleFileUpload} />
          <button onClick={verifyBulkEmails} disabled={isProcessing || emails.length === 0}>
            {isProcessing ? "Processing..." : "Verify Emails"}
          </button>
          {isProcessing && <progress value={progress} max="100">{progress}%</progress>}
        </div>
      )}
      {results.length > 0 && (
        <div>
          {mode === "single" && (
            <table className="results-table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>{result.email}</td>
                    <td>{result.status}</td>
                    <td>{result.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {mode === "bulk" && (
            <button onClick={downloadResults}>Download Results</button>
          )}
        </div>
      )}
    </div>
  );
};

export default Verifier;
